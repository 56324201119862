.location-queue-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .location-item > * {
    flex: 1 1 170px;
  }

  .location-item {
    background-color: #164042;
    color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;

    & + .location-item {
      margin-left: 15px;
    }

    .time {
      font-size: 18px;
      font-weight: 600;
    }

    .location {
      font-size: 14px;
      color: #8ba0a1;
    }
  }
}

.test-location {
  .list-group {
    max-width: 450px;
    margin: 0 auto;
    .list-item {
      a {
        text-decoration: none !important;
      }
    }
  }
}