.drawer-body {
    .content {
        display: grid;
        grid-template-columns: 90%;
        justify-items: center;
        justify-content: center;
        grid-row-gap: 1rem;
        img {
            width: auto;
            height: 300px;
        }
        .list-item {
            width: 100%;
        }
        span, .btn {
            width: 100%;
        }
        .text-required-dot {
            width: 10px;
        }
        .instructions {
            text-decoration: none !important;
            font-weight: 600;
        }
        .barcode {
            border: 1px dashed grey;
            border-radius: 0.6rem;
            width: 100%;
            text-align: center;
            padding: 0.75rem 1.25rem;
            h4 {
                margin: 0;
            }    
        }
    }
}

.medical-drawer {
    display: grid;
    grid-row-gap: 2rem;
    .medical-options {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        // grid-row-gap: 1rem;
        &.test-done {
            .card {
                border-bottom-left-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
                cursor: initial;
                &:hover {
                    box-shadow: none;
                }
            }
        }
        .custom-item .list-group-item {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
        .card {
            justify-items: center;
            display: grid;
            grid-row-gap: 1rem;
            padding: 2rem;
            position: relative;
            //cursor: pointer;
            &.rounded {
                border: 1px solid rgba(0,0,0,0.125);
            }
            &:first-child {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }
            &:last-child {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }
            // &:hover {
            //     box-shadow: 0 0.5rem 1rem rgba(0,0,0, 0.2);
            // }
            i {
                font-size: 4rem;
                width: 4rem;
                color: #175C5F;
            }
            .badge {
                position: absolute;
                top: 0;
                right: 0;
                margin: 0.5rem;
                border-radius: 0.2rem;
                padding: 0.5rem;
            }
        }
    }
    .symptoms, .fqa {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        align-content: center;
        grid-row-gap: 1rem;
        .card {
            justify-items: center;
            display: grid;
            grid-row-gap: 1rem;
            padding: 1rem;
            position: relative;
            cursor: pointer;
            grid-template-columns: auto auto;
            grid-column-gap: 1rem;
            &:hover {
                box-shadow: 0 0.5rem 1rem rgba(0,0,0, 0.2);
            }
            i {
                justify-self: end;
                font-size: 1.5rem;
                width: 1.5rem;
                color: #175C5F;
            }
            h4 {
                margin: 0;
                justify-self: start;
            }
        }
    }
}

.drawer.tabbed-nav {
    .drawer-header {
        height: auto;
        .py-4 {
            padding: 0.5rem !important; 
        }
    }
}


.list-item {
    a {
        text-decoration: none !important;
    }
}

.custom-text {
    position: relative;
    width: 100%;
    span {
        position: absolute;
        margin-bottom: 0;
        z-index: 3;
        font-weight: 600;
        font-size: 16px;
        bottom: 0.4rem;
        // top: 50%;
        // transform: translateY(-50%);
        left: 1rem;
        width: auto !important;
    }
    .text.compact .form-control {
        padding-left: 2.2rem;
    }
    &.error {
        input {
            border: 1px solid #E2134C;    
        }
        p {
            color: #E2134C;
        }
    }
}

.category-cards {
    grid-template-columns: 70%;
    justify-content: center;
    grid-auto-rows: minmax(12rem, 1fr);
}

.tabbed-nav .drawer-header {
    height: 11rem;
}

.drawer .drawer-header {
    position: relative;
    .close {
        position: absolute;
        top: 20px;
        right: 20px;
    }
}

.badge {
    padding: 0.4rem 0.7rem;
    font-size: 90%;
    font-weight: normal;
}

.info {
    .text-success {
        color: #07393C !important
    }
    .list-item .primary {
        display: flex;
        grid-column-gap: 0.5rem;
        i {
            font-size: 20px;
            width: 20px;
            height: 20px;
        }
    }
}

.dropoff {
    display: flex;
    grid-column-gap: 0.5rem;
    text-decoration: none !important;
    font-weight: 600;
}

.pure-green-card .medical-text-container .btn-secondary {
    color: black;
}

#informationOverview {
    .modal-header, .modal-footer {
        border: none;
    }
    .modal-footer {
        justify-content: center;
        flex-direction: column;
    }
    .modal-body {
        min-height: 360px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.custom-item {
    .list-actions {
        width: 3rem;
        .btn {
            background-color: transparent;
            border-color: transparent;
            color: #175c5f;
            &:hover, &:active, &:focus {
                background-color: transparent !important;
                border-color: transparent !important;
                color: #175c5f !important;
                box-shadow: none !important;
            }
        }
    }
    .list-group-item {
        border: none !important;
        border-bottom: 1px solid rgba(0,0,0,0.125) !important;
    }
}

.medical .list-item .list-group-item.active {
    background-color: initial !important;
    p {
      color: #175c5f;
    }
    &:focus {
        background: initial !important;
        border-color: initial !important;
    }
}

.red-icon p i {
  color: #E2134C;
}

.list-group {
    width: 100%;
}

.list-group-flush {
    .list-group-item {
        border-right-width: 0;
        border-left-width: 0;
        border-radius: 0;
        border-top: none;

        &:first-child {
            border-top-width: 0;
        }
    }

    &:last-child {
        .list-group-item:last-child {
            border-bottom-width: 0;
        }
    }
}

#userUpdate .DatePicker {
    width: 100%;
}