$medical-primary: #175c5f;

.basic-layout .content-area {
	height: calc(100% - 7rem) !important;
	min-height: calc(100vh - 7rem) !important;
}

.pure-green {
	.drawer-header {
		background: #00752a !important;
	}

	.a,
	.drawer .btn-link-basic {
		color: #fff !important;
	}
	.nav-icons .nav-link.active {
		background: rgba(0, 0, 0, 0.3) !important;
	}
	.favorite-location {
		background: rgba(0, 0, 0, 0.2) !important;
		.inner {
			background: rgba(0, 0, 0, 0.2) !important;
		}
	}
	.bigicon {
		text-align: center;
		font-weight: bold;
		font-size: 3.5rem;
		i {
			width: 3rem;
			margin-left: -1.5rem;
		}
	}
}

.dropdown {
	a.form-control {
		text-decoration: none;
		color: #000 !important;
	}
}

.dropdown-item {
	text-decoration: none !important;
}

a:not([role="button"]) {
	text-decoration: underline;
	&:focus-visible {
		outline: 1px solid black;
	}
}

.drawer .splash-container img {
	width: 6rem;
	text-align: center;
	left: 50%;
	position: relative;
	margin-left: -3rem;
}

.drawer img {
	width: 6rem;
}

.content-area .badge {
	padding: 0.4rem 0.7rem;
	font-size: 90%;
	font-weight: normal;
	margin-bottom: 0.5rem;
}
.medical a,
.medical .drawer .btn-link-basic {
	color: #175c5f;

	&:hover {
		color: #07373a !important;
	}
}

.opt-btns {
	margin-top: 2rem;
	.medical {
		background-color: transparent;
	}
}

.medical {
	.switch-options a {
		color: #bfbfbf;
	}

	&.drawer {
		.btn-secondary {
			color: #ffffff;

			.btn-secondary,
			&:hover {
				color: #ffffff !important;
			}
		}
	}

	.btn-primary {
		color: #ffffff;
	}

	.btn-primary,
	.btn-outline-secondary,
	.btn-outline-primary {
		&:hover {
			color: #ffffff !important;
		}
	}

	.btn-outline-primary.btn-danger {
		&:focus {
			color: #fff !important;
		}
	}
}

.card.green {
	background-color: #194c4e;
}

.card.grey {
	background-color: rgba(0, 0, 0, 0.125);
	border: 0;
}

.single-question {
	display: flex;
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	padding: 1rem;
	align-items: flex-start;
	flex-direction: row;
	text-align: left;
	&.column {
		flex-direction: column;
	}
	&.vaccine {
		padding-top: 0;
		margin-top: -0.25rem;
	}
}

.single-question-btn-bar {
	display: flex;
	align-items: flex-start;
	margin-top: 0.75em;
}

@mixin answer-option {
	//background: red;
	border-radius: 0.75em;
	padding: 0.5em;
	margin-right: 0.5em;
	border: transparent;
	text-transform: capitalize;
	cursor: pointer;
	text-decoration: none;
	color: #bfbfbf !important;
	background: rgba(0, 0, 0, 0.05);
	font-weight: 300;
}

.unselected-answer-option {
	@include answer-option;

	&:hover {
		box-shadow: 0 0 0 0.2rem rgba(32, 68, 125, 0.15) !important;
		color: #07373a !important;
	}
}

.unselected-answer-option:focus-visible {
	outline: 0.2rem red !important;
}

.selected-answer-option {
	@include answer-option;
	background: #28a745;
	color: white !important;
	&.none {
		background: #dc3545;
	}
}

.single-question-btn-bar {
	.selected-answer-option {
		&:hover {
			color: #fff !important;
		}
	}
}

.question-text {
	flex-grow: 1;
}
.question-toggle {
	.switch-options.inverse .positive {
		background: #28a745;
	}
}

.switch-options {
	flex-grow: 0;
	flex-shrink: 0;
	width: 7rem;
}

@media only screen and (max-width: 566px) {
	.navbar-lift .nav-item {
		width: 21%;
	}

	.atlas-theme .appName {
		display: block;
		opacity: 1;
	}

	.basic-layout .layout-wrapper .header {
		height: 12rem;
	}
	.basic-layout .layout-wrapper .navbar-lift-more {
		margin-bottom: -7.2rem;
		top: -7.2rem;
	}

	.navbar-lift .nav-icons .nav-link {
		font-size: 0.6rem;
	}
}

.splash-text h1 {
	font-size: 1.25rem;
	margin-top: 2rem;
}
.splash-text h3,
.splash-text h4 {
	font-size: 1.1rem;
}
.drawer .medical-dark {
	background: transparent;
}

.bigIcons {
	font-size: 5rem;
	opacity: 0.2;
	i {
		width: 6rem;
	}
}

.test-location .btn {
	padding: 0rem;
}
.favorite-location {
	max-width: 450px;
	margin-top: 2rem;
	background-color: #08393b;
	color: #fff;
	border-radius: 0.6rem;
	text-align: center;
	box-shadow: 0rem 1rem 2rem transparentize(#000000, 0.75);
	line-height: 1.5rem;
	.inner {
		background: darken(#194c4e, 0.5);
		border-radius: 0.6rem;
		text-align: left;
		padding: 1rem;
		.location {
			font-weight: bold;
		}
	}
}
.filler-words {
	opacity: 0.5;
	text-align: right;
}

.queue-wait {
	padding: 1rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.queue-wait__name {
	font-weight: bold;
}

.common-table {
	width: 100%;
}

.flushot {
	background: #0079d166;
	border-radius: 0.6rem;
}

.barcode-container {
	margin-bottom: 2rem;
}

.switch-options {
	text-align: right;
}
.text-black {
	color: #000;
}
.building-list {
	color: #000000;
	div {
		padding: 1rem 2rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		font-weight: bold;
	}
	div:last-child {
		border-bottom: none;
	}
}

.common-table {
	th {
		padding: 0.5rem 1rem;
	}
	tr {
		margin: 1rem 0.5rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.125);
		td:last-child,
		th:last-child {
			text-align: right;
		}
		td:nth-child(2) {
			div {
				padding-left: 0rem;
				margin-left: 0rem;
			}
		}

		th:nth-child(2) {
			padding-left: 0rem;
			margin-left: 0rem;
		}
	}
	div {
		margin: 0.25rem;
		padding: 0.25rem 0.75rem;
		display: inline-block;
		text-align: center;
	}
	.negative {
		border-radius: 0.5rem;
		background: green;
		padding: 0.25rem 0.75rem;
		text-align: center;
		display: inline-block;
		margin: 0.25rem;
		color: #fff;
	}
	.positive {
		border-radius: 0.5rem;
		background: red;
		padding: 0.25rem 0.75rem;
		text-align: center;
		display: inline-block;
		margin: 0.25rem;
		color: #fff;
	}
	.flu {
		border-radius: 0.5rem;
		background: #0079d1;
		padding: 0.25rem 0.75rem;
		text-align: center;
		display: inline-block;
		margin: 0.25rem;
		color: #fff;
	}
}
.steps {
	display: inline-block;
	width: 5rem;
	.steps-icon {
		display: block;
		font-size: 1.5rem;
	}
	.fa-shield {
		opacity: 0.5;
	}
}
.tabbed-nav {
	.drawer-header {
		height: 10rem;
	}
	.lifted-nav {
		margin-top: -7.2rem;
	}
}

.img-barcode {
	width: 100%;
	height: 3.5rem !important;
}

.nav-link:hover {
	//background-color: rgba(0,0,0,0.2);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	//background-color:#08393b;
}

.basic-layout {
	border-radius: 0rem;
}

.nav.nav-icons.nav-center {
	min-height: 6.2rem;
}

.results-curb {
	//overflow-y:scroll;
	//max-height:50vh;
	//min-height:20rem;
}

.basic-layout .nav-item a:focus {
	min-height: 5.7rem;
}

.drawer .nav-item.active {
	background: none !important;
}
.hold {
	.content-area {
		background: #ffffff; /* Old browsers */
		// background: -moz-linear-gradient(-45deg, #ffffff 0%, #8ce7eb 100%) !important; /* FF3.6-15 */
		// background: -webkit-linear-gradient(-45deg, #ffffff 0%, #8ce7eb 100%) !important; /* Chrome10-25,Safari5.1-6 */
		color: #08393b !important;
		.medical-dark {
			background-color: transparent !important;
		}
		.medical-dark .btn {
			color: #08393b !important;
		}
	}
	td .flu {
		color: #fff !important;
	}
	td .negative {
		color: #fff !important;
	}
	td .positive {
		color: #fff !important;
	}
}

.btn.busy-indicator {
	i {
		display: none;
	}
}

.common-table {
	.btn:hover {
		color: #08393b !important;
	}
	.busy-indicator.btn .loader {
		padding: 0;
		margin: 0;
		right: 0.2rem;

		.pulsate-loading {
			padding: 0 !important;
			//color:#ffffff !important; border:3px solid #ffffff !important;
		}
	}
}

.busy-indicator.btn .loader {
	.pulsate-loading {
		color: #08393b !important;
		border: 3px solid #08393b !important;
	}
}

// .loader-page {
//   .busy-indicator.btn .loader {
//     .pulsate-loading
//     {color:white !important; border:3px solid white !important;}
//   }
// }

.medical-dark .profile-wrapper a,
.medical-dark .profile-wrapper .btn-link-basic {
	color: #1a565a !important; // move over to  medical
}

.modal .nav-link {
	color: #1a565a !important;
}

.modal {
	.btn-secondary {
		background: #194c4e; // !important;
		color: #fff; // !important;
		&:hover {
			background: #08393b !important;
			color: #fff !important;
		}
	}
}
table .medical-dark {
	background: none;
}

.modal.medical-dark .form-control {
	color: #fff !important;
	&:focus {
		background-color: rgba(0, 0, 0, 0.3) !important;
	}
}

.loader {
	.spinner > div > div {
		background: #000 !important;
	}
}

.list-group-item.active {
	pointer-events: inherit;
	border: 1px solid rgba(0, 0, 0, 0.125) !important;
	background: none !important;
}

.drawer .nav-icons a:hover,
.drawer .common-table .btn-link-basic:hover {
	background-color: rgba(0, 0, 0, 0.3) !important;
}

.list-group-item.active:focus {
	background-color: transparent !important;
	border: 1px solid rgba(0, 0, 0, 0.125) !important;
	box-shadow: none !important;
}

.modal-body {
	overflow: visible;
}

.list-group-item.disabled,
.list-group-item:disabled {
	background-color: transparent;
	pointer-events: none;
}

.modal .nav-pills a {
	// color:#fff !important;

	&.active {
		color: #fff !important;
		background-color: $medical-primary;
	}
}

.building-access-button {
	background: none !important;
	display: flex;
	padding: 1rem;
	border-radius: 0.6rem;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	border: 1px solid rgba(0, 0, 0, 0.125);
	font-weight: 200;

	&:focus {
		box-shadow: 0 0 0 0.2rem transparentize($medical-primary, 0.8);
	}
}

.pure-green-card {
	background: rgb(0, 171, 67);
	border-radius: 1rem;
	margin-bottom: 1rem;
	background-image: linear-gradient(130deg, #19835a, #1a565a) !important;
	box-shadow: 0rem 1rem 3rem rgba(0, 0, 0, 0.3);
	color: #fff;

	.medical-text-container {
		.btn-secondary {
			background-color: #fff;

			&:hover {
				background-color: #baecee;
			}
		}
	}
}

// PUT THIS IN PURE GREEN

.large-icon i {
	font-size: 3rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	width: 3rem;
}
.pure-green.drawer {
	background: linear-gradient(152deg, rgba(0, 171, 67, 1) 0%, rgba(0, 112, 40, 1) 100%) !important;
}
.medical-dark.medical-dark.pure-green.themed {
	.btn-primary {
		background-color: rgba(0, 0, 0, 0.2) !important;
		border: none !important;
		color: #fff !important;
		&:hover {
			background-color: rgba(0, 0, 0, 0.4) !important;
			color: #fff !important;
		}
	}
}

.vaccinated {
	display: flex;
	float: right;
	margin-top: -2.5rem;
	height: 1rem;
	margin-bottom: 0rem;
	color: #fff !important;
	.icon {
		margin-right: 0.3rem;
	}
	.circle {
		background: #fff;
		border: 1rem;
		height: 0.5rem;
		width: 0.5rem;
		margin-top: -0.9rem;
		margin-left: 0.25rem;
	}
	i {
		color: #00752a;
	}
}

#TestForm {
	.btn-secondary {
		background: #fff !important;
		color: #08393b !important;
		&:hover {
			background: #8ce7eb !important;
			color: #08393b !important;
		}
	}
}

.survey .list-group-item.active {
	background: #194c4e !important;
}

#queueLengthsModal {
	.modal-footer {
		border-top: 0;
	}
}

// this needs to be updated in HUI
.modal.medical {
	background: transparent;
}
.not-vaccinated-reason-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

#custom-btn {
	height: 7em;
	border-radius: 0.75em;
	border: transparent !important;
	flex: 1;
	flex-shrink: 0;
	margin: 0.3em;
	text-decoration: none;
	color: #bfbfbf !important;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.05) !important;
	box-shadow: none;
	width: 130px;
	height: 120px;
	i {
		display: flex;
		justify-content: center;
		font-size: 1.5rem;
		color: #7a7f80;
		width: auto;
		margin-bottom: 0.5rem;
	}
	&:hover {
		box-shadow: 0 0 0 0.2rem rgba(32, 68, 125, 0.15) !important;
		color: #07373a !important;
	}
	&#active {
		background-color: #28a745 !important;
		border-color: rgba(255, 255, 255, 0.3) !important;
		color: #fff !important;

		i {
			color: #fff;
		}
	}
}

#custom-btn-active {
	background-color: #28a745 !important;
	border-color: rgba(255, 255, 255, 0.3) !important;
	color: #fff !important;
	box-shadow: none !important;
	width: 130px;
	height: 120px;

	&:hover {
		box-shadow: none !important;
	}

	i {
		color: #fff;
		display: flex;
		justify-content: center;
		font-size: 1.5rem;
		width: auto;
		margin-bottom: 0.5rem;
	}
}

@mixin not-vaccinated-base-block {
	height: 7em;
	border-radius: 0.75em;
	border: 1px solid rgba(255, 255, 255, 0.3);
	flex: 1;
	flex-shrink: 0;
	margin: 0.3em;
	text-decoration: none;
	color: rgba(255, 255, 255, 0.3) !important;
	cursor: pointer;
}

.not-vaccinated-reason-block {
	@include not-vaccinated-base-block;
}

.not-vaccinated-reason-block-selected {
	@include not-vaccinated-base-block;
	background: #28a745;
	color: white !important;
}

.sidebar .nav-link {
	color: #fff !important;
	&:hover {
		color: #fff !important;
	}
}

.day-selected {
	color: #07373a;
	font-weight: 600;
}

.day-unselected {
	color: rgba(0, 0, 0, 0.3);
	font-weight: 200;
}

// Not the proper solution,
// the inner scroll of the pagecontainer broke and needs a proper solution
@media only screen and (min-width: 768px) {
	#root {
		overflow-y: scroll !important;
	}
}

.hold-container {
	@media screen and (min-width: 768px) {
		max-width: 720px !important;
	}

	hr {
		margin-top: 0;
		margin-bottom: 0;
	}
}

#end-isolation {
	.modal-footer {
		display: none;
	}

	hr {
		margin-left: -1rem;
		margin-right: -1rem;
	}
}

.schedule-to-clear {
	background-color: rgba(0, 0, 0, 0.1);
}

.medical .test-history .list-group-item.active {
	pointer-events: inherit !important;
	background-color: initial !important;

	&:focus {
		background-color: initial !important;
		border-color: rgba(0, 0, 0, 0.125) !important;
	}
}

.big-icon {
	i {
		font-size: 36px;
		color: #000000;
		width: 36px;
		margin-top: 1.5rem;
	}
	&.success {
		i {
			color: #029600;
		}
	}
}

.list-icon-start {
	i {
		font-size: 36px;
		color: #185d60;
		width: 36px;
	}
}

.list-icon-end {
	i {
		font-size: 20px;
		color: #212529;
		width: 20px;
	}
}

.box-item {
	margin-bottom: 2rem;
	//background-color: rgba(51, 156, 162, 0.25);
	border: 1px solid rgba(23, 92, 95, 0.5);
	width: 100%;
	height: 10rem;
	border-radius: 1rem;
	display: flex;
	align-items: center;
	// justify-content: center;
	flex-direction: column;
	cursor: pointer;

	@media screen and (max-width: 576px) {
		height: auto;
	}

	i {
		font-size: 2.5rem;
		width: 4rem;
		color: #07393c;
		margin-top: 1.5rem;
	}

	p {
		font-size: 1rem;
		color: #07393c;
	}

	&:hover {
		box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
	}
}

.inline-heading,
.inline-heading-padded {
	display: inline-block;
}

.inline-heading-padded {
	padding-top: 2rem;
}

.header-back-btn {
	position: absolute;
	top: 1.5rem;

	button > i {
		margin-left: -0.3rem !important;
	}
}

.link-btn {
	button {
		color: #175c5f;

		&:hover {
			color: #175c5f !important;
		}
		&:active {
			text-decoration: none;
		}
		&:focus {
			text-decoration: none;
		}
	}
}

.no-margin {
	p {
		margin-bottom: 0;
	}
}

.report-btn .btn {
	width: 100% !important;
}

.full-width {
	.btn {
		width: 100% !important;
	}
}
